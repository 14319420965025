export const ADMIN_DASHBOARD_LINK: string = "/admin/dashboard/";
export const GIFT_CARDS_LINK: string = "/gift-card";
export const HELP_LINK_BATMAID: string = "/help-section";
export const BECOME_A_BATMAID_LINK = "/become-a-batmaid";
export const CONTACT_LINK_BATMAID = "/contact-us";
export const CONTACT_LINK_BATMAID_LEGACY = "/batmaid-contact-us";
export const CANCELLATION_CONDITION_LINK: string = "/cancellation-conditions";
export const EDIT_BOOKING_LINK: string = "/client/edit/initialize/{uuid}";
export const OUR_SERVICES_GLOBAL_LINK = "/our-services";
export const BECOME_A_BATMAID_GLOBAL_LINK = "/become-batmaid";
export const ABOUT_BATMAID_GLOBAL_LINK = "/about-batmaid";
export const ABOUT_US_GLOBAL_LINK = "/about-us";
export const CLEANING_CHECKLIST_LANDING_PAGE = "/cleaning-checklist";

export const HOME_CLEANING_LINK = "/home-cleaning";
export const EOT_CLEANING_LINK = "/end-of-tenancy";
export const BUSINESS_CLEANING_LINK = "/business-cleaning";

export const LOGIN_WITH_FACEBOOK_LINK = "/remember-url-on-login/facebook";
export const LOGIN_WITH_GOOGLE_LINK = "/remember-url-on-login/google";

export const CALENDLY_B2B_LINK = "https://calendly.com/batmaid-b2b";

export const BATSOFT_LINK = `https://${process.env.BATSOFT_HOST}/{{lang}}`;
export const BATWORK_LINK = `https://${process.env.BATWORK_HOST}/{{lang}}`;

export const AGENT_LOGOUT_LINK = "/cleanup-session-on-logout";

export const AGENT_SWITCH_USER_LINK =
  "/agent/booking/?_switch_user={email}&selected_country=CH";
export const CLIENT_SWITCH_USER_LINK =
  "/admin_redirect/?target=/{lang}/client%23/client/my-cleanings&_switch_user={email}";
