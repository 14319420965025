import styled, { css } from "styled-components";
import { m } from "framer-motion";

import { rgba, transition } from "@ui/helpers";
import { Cancel } from "@ui/Assets/MyIcons";
import { Text } from "@ui/Atoms/Text";

import {
  RootStyledProps,
  ContainerStyledProps,
  BodyStyledProps,
  ImageStyledProps,
} from "./Dialog.types";
import { sizes } from "./Dialog.consts";

export const Root = styled(m.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 },
})<RootStyledProps>`
  --dialog-container-min-height: 328px;
  --dialog-close-button-position: ${props => props.theme.spacing.spacing_16};
  --dialog-horizontal-save-space: ${props => props.theme.spacing.spacing_16};
  --dialog-vertical-save-space: ${props => props.theme.spacing.spacing_16};

  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${props => props.theme.zIndex.modal};

  ${props => props.theme.breakpoints.tablet} {
    --dialog-horizontal-save-space: ${props => props.theme.spacing.spacing_32};
    --dialog-vertical-save-space: ${props => props.theme.spacing.spacing_32};
  }

  ${({ variant, size, direction, theme }) => css`
    // for < desktop all modals behave the same way as vertical
    --dialog-body-width: ${sizes[variant]["vertical"][size]};

    ${theme.breakpoints.desktop} {
      --dialog-body-width: ${sizes[variant][direction][size]};
    }
  `}
`;

export const Backdrop = styled.div`
  position: absolute;
  inset: 0;
  background-color: ${props => rgba(props.theme.palette.blue900, 0.4)};
  z-index: -1;
  cursor: pointer;
`;

export const Container = styled(m.div).attrs({
  initial: { transform: "scale(0.9)" },
  animate: { transform: "scale(1)" },
  exit: { transform: "scale(0.8)" },
  transition: { duration: 0.2 },
})<ContainerStyledProps>`
  position: relative;
  max-width: calc(100% - (var(--dialog-horizontal-save-space) * 2));
  max-height: calc(100% - (var(--dialog-vertical-save-space) * 2));
  min-height: var(--dialog-container-min-height);
  width: var(--dialog-body-width);
  border-radius: ${props => props.theme.border.radius.base};
  background-color: ${props => props.theme.palette.white};
  display: flex;
  overflow: hidden;
  z-index: 1;

  ${props =>
    props.direction === "vertical" &&
    css`
      flex-direction: column;
    `}

  ${({ variant }) =>
    variant === "blank" &&
    css`
      min-height: auto;
    `}

  ${({ variant, direction }) =>
    variant === "image" &&
    direction === "horizontal" &&
    css`
      flex-direction: column;

      ${props => props.theme.breakpoints.desktop} {
        width: auto;
        padding-right: var(--dialog-body-width);
      }
    `}
`;

export const CloseButton = styled.button`
  all: unset;
  position: absolute;
  top: var(--dialog-close-button-position);
  right: var(--dialog-close-button-position);
  padding: ${props => props.theme.spacing.spacing_4};
  color: ${props => props.theme.palette.grey800};
  border-radius: 50%;
  background-color: ${props => props.theme.palette.white};
  transition: ${transition(["color", "background-color"])};
  z-index: 10;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.palette.white};
    background-color: ${props => props.theme.palette.grey800};
  }
`;

export const CloseIcon = styled(Cancel)`
  pointer-events: none;
`;

export const Image = styled.img<ImageStyledProps>`
  max-width: 100%;
  transition: ${({ theme }) =>
    transition(
      "opacity",
      `${theme.animations.durationLong} ${theme.animations.durationShort}`,
    )};
  opacity: 0;
  object-fit: cover;

  ${props => props.theme.breakpoints.desktop} {
    ${props =>
      props.direction === "horizontal" &&
      css`
        min-height: var(--dialog-container-min-height);
      `}
  }

  ${({ isLoaded }) =>
    isLoaded &&
    css`
      opacity: 1;
    `}
`;

export const Body = styled.div<BodyStyledProps>`
  position: relative;
  max-width: 100%;
  min-width: 100%;
  padding: ${props => props.theme.spacing.spacing_24};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.spacing_16};
  scroll-behavior: smooth;
  overscroll-behavior: contain;
  overflow-x: hidden;
  overflow-y: auto;

  ${({ variant, direction }) =>
    variant === "image" &&
    direction === "horizontal" &&
    css`
      ${props => props.theme.breakpoints.desktop} {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 0;
        width: var(--dialog-body-width);
        height: 100%;
      }
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing.spacing_16};
`;

export const Paragraph = styled(Text).attrs({
  as: "p",
  $style: "regular_14",
})`
  margin: 0;
`;
